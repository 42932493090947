import React from "react"
import PropTypes from "prop-types"
// Components
import { Link, graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Img from "gatsby-image"
import { Card, Row, Col } from 'react-bootstrap'
import { SlugLink } from '../components/utils'

const CategoryTemplate = ({ location, pageContext, data }) => {
  const siteTitle = data.site.siteMetadata.title
  const domainName = data.site.siteMetadata.domainName
  const shortName = data.site.siteMetadata.shortName
  // const posts = data.allMarkdownRemark.edges
  const { category } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  let fullTag = category;
  if (edges && edges.length > 0) {
    fullTag = edges[0].node.frontmatter.category;
  }
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
    } "${fullTag}"`
  // if (location != null) {
  //   if (location.href != null && location.href.endsWith('/')) {
  //     window.location.href = window.location.href.substring(0, window.location.href.length - 1);
  //   }
  // }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`${fullTag} - ${shortName}`}
        description={`${fullTag} - ${siteTitle}`}
      // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <div className="row feature-list feature-list-sm">
        <div className="col-12 text-center"><h1>{tagHeader}</h1></div>
          {edges.map(({ node }) => {
            let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div class="col-md-4 post-list-h">
                            <div class="feature-12">
                            <Link to={node.fields.slug} title={title}>
                                <Img fluid={featuredImgFluid} objectFit="cover"
                                    objectPosition="50% 50%"
                                    alt={title} title={title} className="card-img-top" />
                                </Link>
                                <div class="feature__body">
                                    <h4><Link to={node.fields.slug} title={title}>{title}</Link></h4>
                                    <p className="lead" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                    <Link to={node.fields.slug} title={title} className="text-right">
                                        Chi tiết
                                    </Link>
                                </div>
                            </div>
                        </div>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <Link to="/category" className="btn-alltags">Tất cả danh mục</Link>
          </div>
        </div>
      
    </Layout>
    
  )
}
CategoryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        domainName
        shortName
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { fields: { category: { eq: $category } },frontmatter: {date: {lt: "new Date()"}} }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            category
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`

export default CategoryTemplate